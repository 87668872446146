import React, { useEffect, useState } from 'react';
import valid from 'card-validator';
import { useForm, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { CountryCode } from 'teddly-sdk';
import tw from 'twin.macro';
import { FormSubHeading } from '@components/Atoms/Typography';
import { NewPaymentDetails } from '@interfaces';
import StateSelectDropdown from '../../components/StateSelectDropdown';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { useCheckoutShippingAddressContext } from '../../components/ShippingAddressStep/context';
import { NUMBER_ZIP_CODE } from '../../../../../config/validation/index';

import {
  Dialog,
  Button,
  InputBase,
  Switch,
} from '@teddly/teddly-ui-components';

type InputType = NewPaymentDetails & IAddress;

type PaymentDetailsProps = {
  paymentDetails?: InputType;
  onClose?: () => void;
  isOpen?: boolean;
  onSubmit?: (data: {
    cardInputData: NewPaymentDetails;
    billingAddress: IAddress;
  }) => void;
  loading: boolean;
};

const emptyPaymentDetails: InputType = {
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  firstName: '',
  lastName: '',
  city: '',
  streetAddress1: '',
  postalCode: '',
  streetAddress2: '',
  country: {
    code: CountryCode.US,
    country: 'United States of America',
  },
  countryArea: '',
};
export default function AuthorizeNetPaymentDetailsForm({
  paymentDetails,
  onClose,
  isOpen = false,
  onSubmit = () => null,
  loading = false,
}: PaymentDetailsProps) {
  const text = 'Add';
  const { selectedShippingAddress, isCheckoutShippingAddressValid } =
    useCheckoutShippingAddressContext();
  const [isAddressSame, setIsAddressSame] = useState<boolean>(false);

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    control,
    formState: { errors, touchedFields, isValid },
  } = useForm<InputType>({ mode: 'onChange' });
  const [countryArea, setCountryArea] = useState(
    emptyPaymentDetails.countryArea
  );

  useEffect(() => {
    if (isAddressSame) {
      unregister('city');
      unregister('streetAddress1');
      unregister('postalCode');
      unregister('streetAddress2');
      unregister('country');
      unregister('countryArea');
    }
  }, [isAddressSame, isCheckoutShippingAddressValid]);
  useEffect(() => {
    if (!isAddressSame && isCheckoutShippingAddressValid) {
      setIsAddressSame(isCheckoutShippingAddressValid);
    }
  }, [isCheckoutShippingAddressValid]);
  const validateCardNumber = (value) => {
    const valueForValidation = value.replaceAll(' ', '').trim();
    const numberValidation = valid.number(valueForValidation);

    if (valueForValidation.length < 13 || valueForValidation.length > 19) {
      return 'Card number length should be between 13 and 19 digits!';
    }
    if (!numberValidation.isValid) {
      return 'Card number is not valid!';
    }
    return true;
  };

  const handleClose = () => {
    reset(emptyPaymentDetails);
    onClose();
  };

  const Submit = (data: InputType) => {
    const billingAddress = isAddressSame
      ? {
          firstName: selectedShippingAddress.firstName,
          lastName: selectedShippingAddress.lastName,
          city: selectedShippingAddress.city,
          streetAddress1: selectedShippingAddress.streetAddress1,
          postalCode: selectedShippingAddress.postalCode,
          streetAddress2: selectedShippingAddress.streetAddress2,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea: selectedShippingAddress.countryArea,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          streetAddress1: data.streetAddress1,
          postalCode: data.postalCode,
          streetAddress2: data.streetAddress2,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea,
        };

    onSubmit({
      cardInputData: {
        cardNumber: data.cardNumber.replaceAll(' ', ''),
        cvv: data.cvv.replaceAll(' ', ''),
        expiryDate: data.expiryDate.replace('/', ''),
        firstName: data.firstName,
        lastName: data.lastName,
      },
      billingAddress: billingAddress,
    });
    reset(emptyPaymentDetails);
  };
  const limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }
    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }
    return val;
  };

  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), '12');
    const date = limit(val.substring(2, 4), '31');
    return month + (date.length ? `/${date}` : '');
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`${text} Payment Methods`}
      onClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      footerProps={{
        children: [
          <Button
            disabled={loading || !isValid || (!isAddressSame && !countryArea)}
            type="submit"
            onClick={handleSubmit(Submit)}
            title={`${text} Card`}
          />,
        ],
      }}
    >
      <form onSubmit={handleSubmit(Submit)}>
        <div
          css={{
            ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center	`,
          }}
        >
          <InputBase
            name="firstName"
            label="First Name"
            css={tw`w-[300px]	tablet:w-[360px] `}
            {...register('firstName', {
              required: 'Firstname is required !',
            })}
            defaultValue={paymentDetails?.firstName}
            message={errors?.firstName?.message}
            error={errors?.firstName?.message}
          />
          <InputBase
            name="lastName"
            defaultValue={paymentDetails?.lastName}
            label="Last Name"
            css={tw`w-[300px]	tablet:w-[360px] `}
            {...register('lastName', {
              required: 'LastName is required !',
            })}
            message={errors?.lastName?.message}
            error={errors?.lastName?.message}
          />

          <Controller
            name="cardNumber"
            control={control}
            defaultValue={paymentDetails?.cardNumber}
            rules={{
              required: 'Card number is required !',
              validate: (value) => validateCardNumber(value),
            }}
            render={({ field }) => (
              <PatternFormat 
                {...field}
                format="#### #### #### #### ###"
                title="Card Number"
                name="cc-number"
                css={tw`w-[300px]	tablet:w-[436px] `}
                // message={errors?.cardNumber?.message}
                // error={errors?.cardNumber?.message}
                customInput={InputBase}
              />
            )}
          />

          <Controller
            defaultValue={paymentDetails?.expiryDate}
            name="expiryDate"
            control={control}
            rules={{
              required: 'Expiry date is required !',
            }}
            render={({ field }) => (
              <PatternFormat 
                {...field}
                renderText ={cardExpiry}
                format="undefined"
                title="Expiry"
                name="cc-exp"
                css={tw`w-[300px]	tablet:w-[130px] `}
                // message={errors?.expiryDate?.message}
                // error={errors?.expiryDate?.message}
                customInput={InputBase}
              />
            )}
          />

          <Controller
            name="cvv"
            control={control}
            defaultValue={paymentDetails?.cvv}
            rules={{
              required: 'CVV is required !',
            }}
            render={({ field }) => (
              <PatternFormat 
                {...field}
                format="####"
                title="CVV"
                name="cc-csc"
                css={tw`w-[300px]	tablet:w-[130px] `}
                // message={errors?.cvv?.message}
                // error={errors?.cvv?.message}
                customInput={InputBase}
              />
            )}
          />
          {isCheckoutShippingAddressValid && (
            <Switch
              id="isAddressSame"
              label="Billing address same as shipping"
              checked={isAddressSame}
              onChange={() => setIsAddressSame(!isAddressSame)}
            />
          )}
          {(!isAddressSame || !isCheckoutShippingAddressValid) && (
            <>
              <FormSubHeading css={tw`mt-3 uppercase w-full`}>
                Billing Info
              </FormSubHeading>
              {/* //retail change to grid */}
              <div
                css={{
                  ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap`,
                }}
              >
                <InputBase
                  name="streetAddress1"
                  label="Street Address"
                  css={tw`w-60	tablet:w-[464px] `}
                  defaultValue={paymentDetails?.streetAddress1}
                  {...register('streetAddress1', {
                    required: 'Street address is required !',
                  })}
                  message={errors?.streetAddress1?.message}
                  error={errors?.streetAddress1?.message}
                />

                <InputBase
                  name="streetAddress2"
                  label="Apt, suite. (optional)"
                  css={tw`w-60	tablet:w-[255px] `}
                  defaultValue={paymentDetails?.streetAddress2}
                  {...register('streetAddress2')}
                  message={errors?.streetAddress2?.message}
                  error={errors?.streetAddress2?.message}
                />
                <InputBase
                  name="city"
                  label="City"
                  css={tw`w-60	tablet:w-[255px] `}
                  defaultValue={paymentDetails?.city}
                  {...register('city', {
                    required: 'City is required !',
                  })}
                  message={errors?.city?.message}
                  error={errors?.city?.message}
                />
                <StateSelectDropdown
                  value={countryArea}
                  onChange={setCountryArea}
                />
                {/* <InputBase
                        name="countryArea"
                        label="State"
                        css={tw`flex-1`}
                        value="NY"
                      /> */}
                <InputBase
                  name="postalCode"
                  label="Zip Code"
                  css={tw`w-60	tablet:w-[140px] `}
                  defaultValue={paymentDetails?.postalCode}
                  {...register('postalCode', {
                    required: 'Zip code is required !',
                    minLength: NUMBER_ZIP_CODE,
                    maxLength: NUMBER_ZIP_CODE,
                  })}
                  message={errors?.postalCode?.message}
                  error={errors?.postalCode?.message}
                />
              </div>
            </>
          )}
          {/* <MakeDefaultSwitch
                checked={cardInputData.isPrimary}
                onChange={() => {
                  setCardInputData((prevState) => ({
                    ...prevState,
                    isPrimary: !prevState.isPrimary,
                  }));
                }}
              /> */}
        </div>
      </form>
    </Dialog>
  );
}
