import styled from '@emotion/styled';
import tw from 'twin.macro';
import stylesDialog from '../../../../styles/dialog.module.scss';
import FastDeliveryIcon from 'public/assets/90-min.svg';
import { BuildingsIcon } from '@components/Atoms/Icons/BuildingsIcon';
import { HashIcon } from '@components/Atoms/Icons/HashIcon';
import { TruckIcon } from '@components/Atoms/Icons/TruckIcon';
import { UnionIcon } from '@components/Atoms/Icons/UnionIcon';
import { OrderResult } from '@app/checkout/types';
import { getDeliveryTimeHandler, getFullSelectedDateDescription } from './Delivery/utils';
const orderConfirmImg = '/assets/images/orderConfirm.png';
import {
  Dialog,
  Button,
  ButtonVariantEnum,
} from '@teddly/teddly-ui-components';
import { useRoutes } from '@utils/routes';
import moment from 'moment';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';

const OrderInfo = styled.div(tw`w-full mt-8`);
const OrderInfoHeading = styled.h1({
  ...tw`text-charcoal font-semibold max-w-[506px] mb-6 tablet:text-4xl`,
});
const modelBorder = styled.div(tw`rounded-none`);
const buttonKeepShopping = styled.div(
  tw`float-right bg-white border-2 border-black text-black`,
  `>button {
    color:black
  }`
);

const OrderInfoRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(10px, 30px) 1fr;
  gap: 12px;
  padding: 6px;
`;
const OrderMessageMain = styled.div({
  ...tw`
    text-xl
    font-bold
    pt-6


    `,
});
const OrderMessageSecoundery = styled.div({
  ...tw`
  text-base
  font-medium
  pt-2

  `,
});

const StyledHeader = styled(Dialog.Header)`
  background: #fa4f4f;
  border-radius: 12px 12px 0 0;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 27px 27.5px 18px 27px;
  height: auto;
  line-height: 1.5;
  letter-spacing: 0;
  > button {
    width: var(--sz-24, 34px);
    height: var(--sz-24, 34px);
    background: transparent;
    margin-left: auto;
    svg {
      fill: #fff;
    }
  }
`;

const OrderInfoValue = styled.span({
  ...tw`  font-semibold`,
});
const OrderInfoValueDescription = styled.span({
  ...tw`  
  font-bold
  pr-1
  `,
});


const ModalContentWrapper = styled.div({
  ...tw`flex `,
  background: '#f6f6f6',
});

export default function OrderConfirmation({
  orderResult,
  isOpen,
}: {
  orderResult: OrderResult;
  isOpen: boolean;
}) {
  const { router, routes, pages, navigateTo } = useRoutes();

  return (
    <Dialog
      className={stylesDialog.orderConfirmationDialog}
      isOpen={isOpen}
      title={'Order confirmation'}
      onClose={() => router.replace(routes[pages.HOME])}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      footerProps={{
        children: [
          <Button
            data-testid={ids.orderConfirmationKeepShoppingButton}
            type="submit"
            onClick={() => navigateTo({ route: pages.HOME })}
            title={'Keep Shopping'}
          />,
          <Button
            data-testid={ids.orderConfirmationTrackOrderButton}
            variant={ButtonVariantEnum.FILL}
            type="submit"
            onClick={() =>
              navigateTo({ route: `/order-details/${orderResult.id}` })
            }
            title={' Track order'}
          />,
        ],
      }}
    >
      <Dialog.Body className={stylesDialog.dialogBody}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0px',
          }}
        >
          <ModalContentWrapper>
            <img
              data-testid={ids.orderConfirmationImage}
              css={tw`flex w-full 
                    
                    tablet:w-[50%]  
                  desktop:w-[977px]   `}
              src={orderConfirmImg}
              alt=""
            />
          </ModalContentWrapper>

          <OrderMessageMain>We've got your order!</OrderMessageMain>

          <OrderMessageSecoundery data-testid={ids.orderConfirmationOrderMessage}>
            Hold tight! A human is reviewing your order to make sure it's all
            good and ready for fulfillment. Once it’s approved, we'll send an
            email with the order details.
          </OrderMessageSecoundery>

          <OrderInfo>

            <OrderInfoRow data-testid={ids.orderConfirmationOrderNumber}>
              <HashIcon   class="stroke-3" css={tw`text-[#33D1A7] 	`}></HashIcon>
              <OrderInfoValue>
                <OrderInfoValueDescription>
                  Order number:
                </OrderInfoValueDescription>
                {orderResult.number}
              </OrderInfoValue>
            </OrderInfoRow>

            <OrderInfoRow data-testid={ids.orderConfirmationOrderTotal}>
              <UnionIcon css={tw`text-[#33D1A7] text-sm	`} />
              <OrderInfoValue>
                <OrderInfoValueDescription>Total:</OrderInfoValueDescription>$
                {orderResult.total}
              </OrderInfoValue>
            </OrderInfoRow>

            <OrderInfoRow data-testid={ids.orderConfirmationDeliveryAddress}>
              <BuildingsIcon fontSize="small" css={tw`text-[#33D1A7]`} />
              <OrderInfoValue>
                <OrderInfoValueDescription>
                  Delivery Address:
                </OrderInfoValueDescription>
                {orderResult?.shippingAddress?.streetAddress1},
                {orderResult?.shippingAddress?.postalCode}
              </OrderInfoValue>
            </OrderInfoRow>

            <OrderInfoRow data-testid={ids.orderConfirmationDeliveryTime}>
              {orderResult?.isFastDelivery ? <FastDeliveryIcon /> : <TruckIcon fontSize="small" css={tw`text-[#33D1A7]`} />}
              <OrderInfoValue>
                <OrderInfoValueDescription>
                  Delivery time:
                </OrderInfoValueDescription>
                {orderResult?.timeslot
                  ? getDeliveryTimeHandler(orderResult.isFastDelivery, moment(orderResult.created), orderResult.timeslot)
                  : 'As soon as possible...'}
              </OrderInfoValue>
            </OrderInfoRow>
          </OrderInfo>

        </div>
      </Dialog.Body>
    </Dialog>
  );
}
